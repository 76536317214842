.chatboxWrapper{
    width: 50%;
    height: 300px;
    position: fixed;
    border: 1px solid black;
    bottom: 10px;
    right: 80px;
    background-color: white;
}
.chatboxWrapper .chatForm{
    background-color: white;
    position: fixed;
    bottom: 20px;
    z-index: 1;
    margin-left: 10px;
    width: calc(50%-10px);
}
.chatboxWrapper .chatForm .chatInput{
    width: 50%;
    margin-right: 10px;
    height: fit-content;
   overflow-wrap: inherit;
   height: 20px;
}
.chatboxWrapper .chatForm .sendButton {
    height: 25px;
    width: 60px;
    font-weight: 400;
    background-color: lightblue;
    border: solid lightgray;
    box-shadow: 0.5px 1px lightgray;
}